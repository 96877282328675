import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Card } from 'flowbite-react';
import DropzoneComponent from './Dropzone';
import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Video from "yet-another-react-lightbox/plugins/video";
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component
import VideoCarousel from './VideoCarousel';
import { Download } from 'yet-another-react-lightbox/plugins';

const accountName = 'weddingpictures';
const containerName = 'weddingpicturescontainer';
const sasToken = 'sp=rwl&st=2024-09-01T09:00:20Z&se=2025-02-01T18:00:20Z&sv=2022-11-02&sr=c&sig=JuXddMX%2FurQwdcCVw1Q705TXQt8vUG0seD%2BTE%2FJx9MM%3D';

const blobServiceClient = new BlobServiceClient(
    `https://${accountName}.blob.core.windows.net?${sasToken}`
);
const containerClient = blobServiceClient.getContainerClient(containerName);


function WeddingPhotos() {
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState();
    const [index, setIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState();
    const [videos, setVideos] = useState();
    const fetchFiles = async () => {
        setLoading(true);
        try {
            const blobItems = containerClient.listBlobsFlat();
            let blobs = [];
            for await (const blob of blobItems) {
                blobs.push({
                    name: blob.name,
                    url: containerClient.getBlockBlobClient(blob.name).url
                });
            }
            setUploadedFiles(blobs);
        } catch (error) {
            console.error('Error fetching files:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    useEffect(() => {
    if (uploadedFiles != null) {
        setLoading(true);
        setVideos(uploadedFiles
            .filter((file) => /\.mp4|\.mpeg|\.webm$/i.test(file.url))
            .map((file) => ({ src: file.url })))
        
            const loadImages = async () => {
            const photosWithDimensions = await Promise.all(
                uploadedFiles
                    .filter((file) => !/\.mp4|\.mpeg|\.webm$/i.test(file.url))
                    .map(
                        (file) =>
                            new Promise((resolve) => {
                                const img = new Image();
                                img.src = file.url;
                                img.onload = () => {
                                    resolve({
                                        src: file.url,
                                        width: img.naturalWidth,
                                        height: img.naturalHeight,
                                    });
                                };
                            })
                    )
            );
            setPhotos(photosWithDimensions);
            setLoading(false);
        };

        loadImages();
        

        } else {
            setLoading(false)  

        }
    }, [uploadedFiles]);

    const handleUpload = async () => {
        setLoading(true)
        try {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const blockBlobClient = containerClient.getBlockBlobClient(file.name);
                await blockBlobClient.uploadBrowserData(file);
                console.log(`Uploaded ${file.name}`);
            }
            toast.success('Bilder erfolgreich hochgeladen!');
            // Re-fetch the list of files to update the UI
            setFiles([]);
            await fetchFiles();
        } catch (error) {
            toast.error('Fehler beim hochladen der Bilder!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card id="hochzeitsfotos" className="mt-8 flex flex-col items-center">
            {loading && <LoadingOverlay />}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Bounce}
            />
            {/* link should go here:  https://missfrecklesphotography.pic-time.com/VCwhaWQffQmoD */}
            <h2 className="text-5xl font-brush my-4 text-center">Hochzeitsfotos</h2>
            <a href="https://missfrecklesphotography.pic-time.com/VCwhaWQffQmoD" target="_blank" rel="noreferrer" className="text-center text-3xl font-brush font-bold underline text-green-700 my-4">Hier geht es zu den professionellen Hochzeitsfotos</a>
            <p className='text-center'>Habt ihr zusätzlich Hochzeitsfotos oder Videos, die ihr gerne mit uns und anderen teilen wollt, dann könnt ihr sie hier hochladen.</p>
            <DropzoneComponent files={files} setFiles={setFiles} />
            <Button
                outline
                gradientDuoTone="greenToBlue"
                onClick={handleUpload}
                disabled={files.length === 0}
                className='w-fit'
            >
                Bilder hochladen
            </Button>
            {photos?.length > 0 && (
            <>
            <h2 className="text-3xl font-brush my-4 text-center">Fotos</h2>
            <div className='object-contain'>

            <MasonryPhotoAlbum photos={photos} onClick={({ index }) => setIndex(index)} />
            </div>
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Video, Download]}
            />
            </>)}
            {videos?.length > 0 && (
                <><h2 className="text-3xl font-brush my-4 text-center">Videos</h2>
                    <div className="h-[30rem] sm:h-[50rem]">
                        <VideoCarousel videos={videos} />
                    </div>
                </>
            )}
            {photos?.length === 0 && videos?.length === 0 && (
                <p className="text-center">Sobald Bilder oder Videos hochgeladen werden, sind sie hier sichtbar.</p>
            )}
        </Card>
    );
}

export default WeddingPhotos;