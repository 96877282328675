import React, { useState } from "react";

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === "108") {
      onLogin();
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSubmit} className="text-center">
        <label htmlFor="password" className="font-brush text-6xl mb-4 block">
          Login:
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          className="border border-gray-400 px-4 py-2 mx-2 rounded-lg mb-4"
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Login
        </button>
        <p><i>Kleiner Hinweis, das Passwort findet ihr auf der Rückseite des Fotos.</i></p>
      </form>
    </div>
  );
};

export default Login;
