import React from 'react';
import { Carousel } from 'flowbite-react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function VideoCarousel({ videos }) {

    return (
        <div className="relative h-full">

            <Carousel
                leftControl={<div
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-700 text-white p-2 rounded-full"
                >
                    <FaChevronLeft size={20} />
                </div>}
                rightControl={<div
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-700 text-white p-2 rounded-full"
                >
                    <FaChevronRight size={20} />
                </div>}
                className="h-full w-full"
                draggable={false}
                slide={false}
            >
                {videos.map((vid) => (
                    <video
                        key={vid.src}
                        controls
                        src={vid.src}
                        className="rounded-xl max-h-full max-w-full w-auto h-auto object-contain mx-auto"
                    />
                ))}
            </Carousel>

        </div>
    );
}

export default VideoCarousel;
