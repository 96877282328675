import React from "react";
import StoryElement from "./StoryElement";
import { GiSpotedFlower } from "react-icons/gi";
import { Card } from "flowbite-react";

export default function Story() {
  const story = [
    {
      description: { title: "Das erste kennenlernen", date: "08. November 2019", text: "In der Kletterhalle in Wien am 8. November 2019 kreuzten unsere Blicke sich zum ersten Mal. Wir hatten uns beide für den Boulder-Instruktor-Kurs angemeldet, und zwischen den Griffen und Herausforderungen des Kurses entstand sofort eine besondere Verbindung.", texttwo: "Mit jedem gemeinsamen Schritt, den wir machten, wurden das Erklimmen neuer Höhen nicht nur eine Metapher, sondern unsere gemeinsame Reise." },
      img: "/khw.png",
      icon: <GiSpotedFlower />,
    },
    {
      description: {
        title: "Abenteuer",
        date: "Immer",
        text: "Gemeinsam haben wir unzählige Abenteuer erlebt, die unsere Bindung gestärkt und unsere Liebe vertieft haben. Von aufregenden Klettertouren in Spanien und Kroatien bis hin zur Weitwanderung durch Schweden. Unser treuer Campervan namens Mori hat uns zu fernen Orten geführt, wo wir gemeinsam die Schönheit der Natur genossen und tiefe Verbundenheit erlebten.", texttwo: "Jedes Abenteuer war eine Reise, die uns näher zueinander brachte und uns daran erinnerte, dass das Leben am schönsten ist, wenn wir es gemeinsam erleben.",
      },
      img: "/berg.png",
      icon: <GiSpotedFlower />,
    },
    {
      description: {
        title: "Er hat gefragt, sie hat Ja gesagt",
        date: "20. September 2023",
        text: "An einem sonnigen Tag in Albarracín, unserem Lieblingsklettergebiet der Welt im Sektor Techos, habe ich den mutigen Schritt gewagt und Marie einen Heiratsantrag gemacht.", texttwo: "Dieser Ort, der zu unserem kleinen Glücksort geworden ist, passte perfekt zu diesem besonderen Anlass. Es war ein Moment voller Liebe und Aufregung, der uns für immer an diesen magischen Ort binden wird.",
      },
      img: "/ring.png",
      icon: <GiSpotedFlower />,
    },
    {
      description: {
        title: "Die Standesamtliche",
        date: "06. März 2024",
        text: "Inmitten unserer liebsten Familie haben wir unsere standesamtliche Hochzeit zelebriert. Es war ein intimer und herzlicher Moment, der uns für immer verbindet.", texttwo: "Mit Liebe und Dankbarkeit im Herzen haben wir diesen besonderen Tag genossen, gefüllt mit unvergesslichen Erinnerungen und der Gewissheit, dass wir gemeinsam eine wundervolle Zukunft vor uns haben.",
      },
      img: "/standesamtliche.png",
      icon: <GiSpotedFlower />,
    },
    {
      description: { title: "Hochzeitsfeier", date: "10. August 2024", text: "Wir freuen uns gemeinsam mit euch ein weiteres Kapitel in unserem Leben zu feieren!" },
      img: "/hochzeitsfeier.png",
      icon: <GiSpotedFlower />,
    },
  ];

  return (
    <Card id="geschichte" className="mt-8 flex flex-col items-center">
      <h2 className="text-5xl font-brush mb-4 text-center">Unsere Geschichte</h2>
      {story.map((element, index) => {
        return (
          <StoryElement
            key={index}
            customClass={`flex justify-around w-full h-fit ${index % 2 === 0 ? "flex-col md:flex-row text-right" : "flex-col md:flex-row-reverse"
              }`}
            img={element.img}
            description={element.description}
            icon={element.icon}
          />
        );
      })}
    </Card>
  );
}
