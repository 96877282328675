
"use client";

import { Carousel } from "flowbite-react";
const imageNumbers = Array.from({ length: 68 }, (_, i) => i + 1);

const shuffledImageNumbers = imageNumbers.sort(() => Math.random() - 0.5);

const Gallery = () => {
  return (
    <div className="h-56 mt-4 sm:h-64 xl:h-80 2xl:h-96">
      <Carousel draggable={false} slideInterval={3000} indicators={false}>
      {shuffledImageNumbers.map((num) => (
            
          <img loading="lazy" key={num} src={`/${num}.jpeg`} alt={`Hochzeit Foto ${num}`} className="rounded-xl max-h-full max-w-full w-auto h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 object-contain" />
        ))}

      </Carousel>
    </div>
  );
}

export default Gallery;