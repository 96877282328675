import React from "react";
import { Card } from "flowbite-react";


const Location = () => {

    return (
        <Card id="woundwann" className="mt-8 container mx-auto py-8 px-4">
            <h2 className="text-5xl font-brush mb-4 text-center">Die Deantnerin</h2>
            <p>Wir haben uns entschieden, unsere Liebe in den Bergen zu feiern. Die "Deantnerin" als unsere Hochzeitslocation zu wählen, lag daher nahe, da wir beide eine starke Verbindung zu den Bergen empfinden.</p>
            <p>Die beeindruckende Kulisse des Hochkönigs bildet den perfekten Hintergrund für unseren besonderen Tag und spiegelt unsere Liebe zur Natur wider. Die rustikale Eleganz der "Deantnerin" und ihre herzliche Gastfreundschaft schaffen eine Atmosphäre, in der wir uns wohl fühlen und unsere Liebe feiern können.</p>
            <p>Ihr könnt bereits jetzt einen ersten Blick auf die Location werfen und weitere Details auf der Website der "Deantnerin" finden: <a className="underline" href="https://www.deantnerin.at/" target="_blank" rel="noopener noreferrer">www.deantnerin.at</a>.</p>
            <p>Wir freuen uns darauf, diesen unvergesslichen Tag mit euch zu teilen und euch schon bald mehr über den Ablauf unserer Feierlichkeiten mitzuteilen.</p>        
            <img src="/deantnerin.webp" alt="deantnerin"></img>
        </Card>
    );
};

export default Location;
