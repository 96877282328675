import React from "react";

export default function StoryElement({ customClass, description, img, icon }) {
  const { title, date, text, texttwo } = description;
  return (
    <div className={customClass}>
      <div className="flex flex-col w-full md:w-5/12 my-4">
        <div className="text-4xl font-brush font-medium text-emerald-800">{title}</div>
        <div className="text-yellow-800 font-bold">{date}</div>
        <div>{text}</div>
        <div>{texttwo}</div>
      </div>
      <div className="flex flex-col items-center self-center h-fit md:h-80 w-80 md:w-auto">
  <div className="flex-1 border-l-2 border-zinc-300"></div>
  <div className="relative z-10 flex text-yellow-700 bg-zinc-300 rounded-full p-4 self-center flex-shrink mx-auto">
    {icon}
  </div>
  <div className="border-t-2 relative -top-6 border-zinc-300 w-full md:hidden"></div> {/* Single horizontal border for small screens */}
  <div className="flex-1 border-l-2 border-zinc-300 h-full"></div>
</div>

      <div className="w-5/12 self-center">
        <img src={img} alt={title} className="w-full h-64 object-contain" />
      </div>
    </div>
  );
}
