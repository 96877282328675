// NavBar.js
import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Navbar } from "flowbite-react";

const NavBar = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Navbar fluid rounded className="font-brush sticky top-0 z-50 bg-gray-100 shadow-lg text-[#5f061f] font-bold">
      <Navbar.Brand as={Link} to="/" href="https://www.marieundarmin.com">
      <img src="/logo.jpg" alt="logo" className="h-16 sm:h-28 w-16 sm:w-28" onClick={scrollToTop}
 />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="text-right">
      <Link
              to="hochzeitsfotos"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
            Hochzeitsfotos
            </Link>
            <Link
              to="geschichte"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
              Unsere Geschichte
            </Link>
            <Link
              to="woundwann"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
              Location
            </Link>
            {/* <Link
              to="zeitplan"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
              Zeitplan
            </Link>
            <Link
              to="hotel"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
              Hotel
            </Link>
            <Link
              to="einladung"
              smooth
              className="hover:text-emerald-600 cursor-pointer hover:text-yellow-800 text-lg lg:text-xl"
              offset={-200}
            >
              Einladung
            </Link> */}
            </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
