import { Card } from "flowbite-react";
import React, { useState, useEffect, useMemo } from "react";

const Countdown = () => {
  const targetDate = useMemo(() => new Date("2024-08-10T14:00:00"), []);
  const [timeSince, setTimeSince] = useState(calculateTimeSince(targetDate));

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeSince(calculateTimeSince(targetDate));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [targetDate]);

  function calculateTimeSince(targetDate) {
    const now = new Date();
    const difference = now - targetDate;

    if (difference <= 0) {
      // Event hasn't occurred yet, return zeros
      return {
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const months = Math.floor(
      difference / (1000 * 60 * 60 * 24 * 30)
    );
    const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { months, days, hours, minutes, seconds };
  }

  return (
    <Card id="countdown" className="mt-8 text-center text-xl font-brush">
      <h2 className="text-3xl font-bold">Verheiratet seit</h2>
      <div className="hidden mx-0 md:mx-4 lg:mx-8 sm:grid grid-flow-col text-center sm:flex-col">
        <div className="w-full">
          <div className="text-emerald-600 text-3xl">{timeSince.months}</div>
          <div>Monate</div>
        </div>
        <div className="w-full">
          <div className="text-emerald-600 text-3xl">{timeSince.days}</div>
          <div>Tage</div>
        </div>
        <div className="w-full">
          <div className="text-emerald-600 text-3xl">{timeSince.hours}</div>
          <div>Stunden</div>
        </div>
        <div className="w-full">
          <div className="text-emerald-600 text-3xl">{timeSince.minutes}</div>
          <div>Minuten</div>
        </div>
        <div className="w-full">
          <div className="text-emerald-600 text-3xl">{timeSince.seconds}</div>
          <div>Sekunden</div>
        </div>
      </div>
      <div className="flex sm:hidden justify-items-center gap-y-4 justify-items-start flex-col">
        <div className="flex justify-center gap-x-4"><div className="text-emerald-600">{timeSince.months}</div><div>Monate seit</div></div>
        <div className="flex justify-center gap-x-4"><div className="text-emerald-600">{timeSince.days}</div><div>Tage seit</div></div>
        <div className="flex justify-center gap-x-4"><div className="text-emerald-600">{timeSince.hours}</div><div>Stunden seit</div></div>
        <div className="flex justify-center gap-x-4"><div className="text-emerald-600">{timeSince.minutes}</div><div>Minuten seit</div></div>
        <div className="flex justify-center gap-x-4"><div className="text-emerald-600">{timeSince.seconds}</div><div>Sekunden seit</div></div>
      </div>
      <div className="my-8 flex justify-center items-center w-full">
        <img src="/marie.png" alt="Marie" className="w-1/3" />
        <div className="flex items-center mx-4 flex flex-col text-4xl"><span>Marie</span><span>&</span><span>Armin</span></div>
        <img src="/armin.png" alt="Armin" className="w-1/3" />
      </div>
    </Card>
  );
};

export default Countdown;
