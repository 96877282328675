// import React from "react";
// import CountdownLive from "./components/CountdownLive";

import React, { useEffect, useState } from "react";
import Login from "./components/Login";
import Countdown from "./components/Countdown";
import NavBar from "./components/NavBar";
import Story from "./components/Story";
// import Invitation from "./components/Invitation";
import Gallery from "./components/Gallery";
import Location from "./components/Location";
// import Zeitplan from "./components/Zeitplan";
// import Hotel from "./components/Hotel";
import WeddingPhotos from "./components/WeddingPhotos";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem("isLoggedIn", "true");
  };

  useEffect(() => {
    const storedIsLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);
// 
  return (
    <>
      {isLoggedIn && <NavBar />}
      <main className="container mx-auto mb-8 text-zinc-950">
      
        {!isLoggedIn ? (
          <Login onLogin={handleLogin} />
        ) : (
          <div className="flex flex-col gap-y-4">
            <WeddingPhotos />
            <Countdown />
            <Story />
            <Location />
            {/* <Zeitplan /> */}
            {/* <Invitation /> */}
            {/* <Hotel /> */}
            <Gallery />
          </div>
        )}
        
      </main> 
    </>
  );
}

export default App;
