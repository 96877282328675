import { Spinner } from 'flowbite-react';

function LoadingOverlay() {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <Spinner size="xl" aria-label="Loading" />
        </div>
    );
}

export default LoadingOverlay;